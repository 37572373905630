<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>

            <b-breadcrumb-item :to="{ name: listRouteName, }">
              {{ listTitle }}
            </b-breadcrumb-item>
            <b-breadcrumb-item active>優惠券序號</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay :show="showLoading">
          <b-card>
            <div class="row d-flex mb-4 mb-xl-2 justify-content-between">
              <h4 class="col-12 col-xl-6 mb-2 mb-xl-0 font-weight-bold">
                優惠券序號
              </h4>
              <b-input-group class="col-12 col-xl-6 mb-2">
                <template v-if="isDeleteMode">
                  <b-button variant="outline-dark" class="mr-2" @click="closeDeleteMode">取消</b-button>
                  <b-button variant="danger" class="mr-2" :disabled="!selectedRedeemCodes.length" @click="deleteRedeemCodes">刪除序號</b-button>
                </template>
                <template v-else>
                  <b-button
                    variant="primary"
                    class="mr-2"
                    @click="handleExport"
                  ><i class="mdi mdi-download"></i>匯出</b-button>
                  <b-dropdown
                    :disabled="disableAssignButton"
                    ref="settingDropdown"
                    text="指派序號"
                    variant="primary"
                    class="mr-2"
                  >
                    <b-dropdown-item @click="showBatchAssignModal">指定批次會員</b-dropdown-item>
                    <b-dropdown-item @click="showAssignModal(null)">指定單一會員</b-dropdown-item>
                  </b-dropdown>
                  <b-dropdown
                    :disabled="disableModifyButton"
                    ref="settingDropdown"
                    text="新增/刪除序號"
                    variant="primary"
                    class="mr-2"
                  >
                    <b-dropdown-item @click="showUploadModal">批次上傳序號</b-dropdown-item>
                    <b-dropdown-item @click="showCreateModal">新增單筆序號</b-dropdown-item>
                    <b-dropdown-item @click="openDeleteMode">刪除序號</b-dropdown-item>
                  </b-dropdown>
                </template>
                <b-form-input
                  v-model="query.keyword"
                  placeholder="搜尋序號、會員名稱、手機、外部編碼"
                  v-on:keyup.enter="handlePageReset"
                ></b-form-input>
                <b-input-group-append>
                  <b-button @click="handlePageReset"><i class="fa fa-search"></i ></b-button>
                </b-input-group-append>
              </b-input-group>
              <div class="col-12 col-xl-8">
                <span>優惠券名稱：{{ redeemCodeBundle.name }}</span><br/>
                <span>序號總數：{{ redeemCodesMeta.total }}</span><br/>
                <span>未指派數目：{{ redeemCodesMeta.not_belong_count }}</span><br/>
                <span>未領取數目：{{ redeemCodesMeta.belong_count }}</span><br/>
                <span>已領取數目(未使用)：{{ redeemCodesMeta.apply_count }}</span><br/>
                <span>已領取數目(已使用)：{{ redeemCodesMeta.redeem_count }}</span><br/>
              </div>
            </div>

            <b-card-text>
              <div class="row">
                <div class="col-12">
                  <b-overlay rounded="sm">
                    <b-table
                      striped
                      hover
                      responsive
                      :items="redeemCodes"
                      :fields="fields"
                      @sort-changed="handleSort"
                    >
                      <template #head(checkbox)="">
                        <b-form-checkbox v-model="isSelectAll" @change="toggleSelectAll"></b-form-checkbox>
                      </template>
                      <template #cell(checkbox)="data">
                        <b-form-checkbox
                          :value="data.item.id"
                          v-model="selectedRedeemCodes"
                          @change="toggleSelect"
                        ></b-form-checkbox>
                      </template>
                      <template #cell(actions)="data">
                        <b-button
                          class="ml-2"
                          variant="inverse-info"
                          @click="showAssignModal(data.item)"
                          v-if="fromMyConsole || checkPermission([consts.STAFF_CUSTOMER_VIEW])"
                          :disabled="data.item.belong_at !== null"
                        >指派
                        </b-button>
                        <b-button
                          class="ml-2"
                          variant="inverse-success"
                          v-if="(fromMyConsole || checkPermission([consts.STAFF_CUSTOMER_VIEW])) && data.item.customer_id !== null"
                          :disabled="data.item.redeem_at !== null"
                          @click="pushRedeemCode(data.item)"
                        >發送推播
                        </b-button>
                      </template>
                    </b-table>
                  </b-overlay>
                </div>
              </div>
              <CustomPagination
                :currentPage="query.page"
                :totalRows="totalRows"
                :perPage="query.per_page"
                @page-change="handlePageChange"
                @per-page-change="handlePerPageChange"
              />
            </b-card-text>
          </b-card>
        </b-overlay>
      </div>
    </div>

    <b-modal id="modal-batch-assign" title="整批指派序號">
      <b-overlay :show="showLoadingUpload">
        <template v-if="batchAssignStep == 1">
          <div class="form-group">
            <p class="text-danger">依 LINE ID、手機號碼 順序比對會員</p>
            <b-form-file plain v-model="batchAssignFile" placeholder="尚未選擇檔案" browse-text="瀏覽"></b-form-file>
            <b-button
              class="mt-2"
              size="sm"
              variant="outline-primary"
              href="/excel/batch_assign_redeem_code.xlsx"
            >
              <i class="fa fa-file-text"></i>下載範例檔案
            </b-button>
          </div>
        </template>
        <template v-else-if="batchAssignStep == 2">
          <div class="form-group">
            <p>
              上傳資料筆數：{{ batchAssignAnalysis.total_member_count }}<br/>
              對應會員數量：{{ batchAssignAnalysis.valid_member_count }}<br/>
              可用序號數量：{{ batchAssignAnalysis.valid_redeem_code_count }}<br/>
            </p>
            <p v-if="!canBatchAssign" class="text-danger">
              可用序號不足，無法匯入
            </p>
            <p v-if="batchAssignAnalysis.valid_member_count != batchAssignAnalysis.total_member_count" class="text-danger">
              上傳資料數量與對應會員數量不同，請問是否確定匯入
            </p>
          </div>
        </template>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button variant="outline-danger" size="sm" class="float-right" @click="cancel()">
          取消
        </b-button>
        <b-button
          v-if="batchAssignStep == 1"
          size="sm"
          variant="outline-primary"
          @click="preProcessBatchAssign"
          :disabled="!batchAssignFile"
        >
          下一步
        </b-button>
        <b-button v-if="batchAssignStep == 2" size="sm" variant="outline-primary" @click="goBackBatchAssign">回上一步</b-button>
        <b-button
          v-if="batchAssignStep == 2"
          size="sm"
          variant="outline-primary"
          @click="batchAssign"
          :disabled="!canBatchAssign"
        >
          確定匯入
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-upload" title="上傳序號">
      <b-overlay :show="showLoadingUpload">
        <template v-if="imoprtRedeemCodeStep == 1">
          <div class="form-group">
            <label class="flex-shrink-0 mr-2 mb-0">請上傳帳號 Excel 檔案</label>
            <ul class="text-danger">
              <li>第一欄位為序號，第二欄位為 LINE UID，第三欄位為手機號碼</li>
              <li>第一欄序號不能留空，若該序號不指定會員，第二欄 LINE UID與三欄位手機號碼可以留空（可事後批次補匯入會員）</li>
              <li>會員將依 LINE UID、手機號碼順序比對</li>
            </ul>
            <b-form-file plain v-model="file" placeholder="尚未選擇檔案" browse-text="瀏覽"></b-form-file>
            <b-button
              class="mt-2"
              size="sm"
              variant="outline-primary"
              href="/excel/event_redeem_code_list_v2.xlsx"
              ><i class="fa fa-file-text"></i>下載範例檔案</b-button
            >
          </div>
        </template>
        <template v-else-if="imoprtRedeemCodeStep == 2">
          <div class="form-group">
            <p>
              本次上傳序號總數：{{ imoprtRedeemCodeAnalysis.total_code_count }}<br/>
              本次新上傳序號：{{ imoprtRedeemCodeAnalysis.new_code_count }}<br/>
              原本已存在序號：{{ imoprtRedeemCodeAnalysis.already_code_count }}<br/>
            </p>
            <p>
              本次上傳的會員數量：{{ imoprtRedeemCodeAnalysis.assign_count }}<br/>
              本次新指派會員數：{{ imoprtRedeemCodeAnalysis.new_assigne_count }}<br/>
              原本已指派會員數：{{ imoprtRedeemCodeAnalysis.already_assign_count }}<br/>
              指派失敗的會員數：{{ imoprtRedeemCodeAnalysis.fail_assign_count }}<br/>
            </p>
          </div>
        </template>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-danger"
          size="sm"
          class="float-right"
          @click="cancel()"
        >
          取消
        </b-button>
        <b-button
          v-if="imoprtRedeemCodeStep == 1"
          size="sm" variant="outline-primary"
          @click="preProcessImoprtRedeemCode"
          :disabled="!file"
        >
          下一步
        </b-button>
        <b-button v-if="imoprtRedeemCodeStep == 2" size="sm" variant="outline-primary" @click="goBackImport">回上一步</b-button>
        <b-button v-if="imoprtRedeemCodeStep == 2" size="sm" variant="outline-primary" @click="imoprtRedeemCode">確定上傳序號</b-button>
      </template>
    </b-modal>

    <b-modal id="modal-create" title="請輸入序號">
      <b-overlay :show="showLoadingUpload">
        <b-form-input v-model="redeemCodeInput"></b-form-input>
      </b-overlay>
      <template #modal-footer="{ cancel }">
        <b-button
          variant="outline-danger"
          size="sm"
          class="float-right"
          @click="cancel()"
        >
          取消新增
        </b-button>
        <b-button size="sm" variant="outline-primary" @click="saveRedeemCode">確認送出</b-button>
      </template>
    </b-modal>

    <StaffAddCustomer
      ref="staffAddCustomer"
      :show-btn="false"
      title="指派優惠券"
      :only-belonged-customer="fromMyConsole"
      @bind="assignRedeemCode"
    ></StaffAddCustomer>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex"
import merchantApi from "@/apis/merchant";
import redeemCodeBundleApi from "@/apis/redeemCodeBundle"
import { format } from "date-fns"
import myConsoleMixin from '@/mixins/Dashboard/RedeemCodeBundle/myConsole';
import PermissionChecker from '@/utils/PermissionChecker'
import * as consts from '@/consts'
import StaffAddCustomer from "@/pages/Dashboard/Staff/StaffAddCustomer.vue";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import {paginationMixin} from "@/mixins/pagination";
import {updateQueryFromRoute, updateUrl} from "@/utils/updateUrl";
import redeemCodeApi from "@/apis/redeem-code";

export default {
  components: {CustomPagination, StaffAddCustomer },
  mixins: [myConsoleMixin, paginationMixin],
  data() {
    const baseFields = [
      {
        key: "code",
        label: "序號",
      },
      {
        key: "customer",
        label: "會員名稱",
        formatter: (value) => {
          if (value && value.name) {
            return this.hiddenString(value.name, 20)
          }
          return ''
        },
      },
      {
        key: "branch.name",
        label: "分店名稱",
      },
      {
        key: "branch.branch_code",
        label: "分店代號",
      },
      {
        key: "belong_at",
        label: "指派時間",
        formatter: (value) => {
          return value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : null
        },
        sortable: true,
      },
      {
        key: "apply_at",
        label: "領取時間",
        formatter: (value) => {
          return value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : null
        },
        sortable: true,
      },
      {
        key: "redeem_at",
        label: "使用時間",
        formatter: (value) => {
          return value ? format(new Date(value), 'yyyy-MM-dd HH:mm:ss') : null
        },
        sortable: true,
      },
      {
        key: "is_enabled",
        label: "啟用/停用",
        formatter: (value) => {
          return value ? '啟用' : '停用'
        },
      },
      {
        key: "status",
        label: "使用狀況",
        formatter: (value, key, item) => {
          switch (true) {
            case !!item.redeem_at:
              return '已兌換'
            case !!item.apply_at:
              return '已領取'
            case !!item.belong_at:
              return '已歸戶'
            default:
              return '未領取'
          }
        },
        sortable: true,
      },
      {
        key: "actions",
        label: "管理",
      },
    ];

    return {
      consts: consts,
      showLoading: false,
      showLoadingUpload: false,
      validationErrors: null,
      redeemCodeBundle: {
        name: null,
        redeemcode_type: null,
      },
      batchAssignFile: null,
      batchAssignStep: 1,
      batchAssignAnalysis: {
        total_member_count: 0,
        valid_member_count: 0,
        valid_redeem_code_count: 0,
      },
      file: null,
      imoprtRedeemCodeStep: 1,
      imoprtRedeemCodeAnalysis: {
        total_code_count: 0,
        new_code_count: 0,
        already_code_count: 0,
        assign_count: 0,
        new_assigne_count: 0,
        already_assign_count: 0,
        fail_assign_count: 0,
      },
      redeemCodes: [],
      redeemCodesMeta: {},
      redeemCodeInput: '',
      baseFields: baseFields,
      fields: baseFields,
      isDeleteMode: false,
      selectedRedeemCode: null,
      selectedCustomerId: null,
      lineMerchant: {},
      isSelectAll: false,
      selectedRedeemCodes: [],
      totalRows: 0,
      query: {
        page: 1,
        per_page: 20,
        keyword: '',
        sort_by: 'id',
        sort_desc: 'desc',
      },
      initialized: false,
    }
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    canBatchAssign() {
      return this.batchAssignAnalysis.valid_redeem_code_count > this.batchAssignAnalysis.valid_member_count
    },
    disableAssignButton() {
      return this.redeemCodeBundle.redeemcode_type === consts.REDEEM_CODE_BUNDLE_REDEEMCODE_TYPES.NONE
    },
    disableModifyButton() {
      return this.redeemCodeBundle.redeemcode_type === consts.REDEEM_CODE_BUNDLE_REDEEMCODE_TYPES.FIXED
        || this.redeemCodeBundle.redeemcode_type === consts.REDEEM_CODE_BUNDLE_REDEEMCODE_TYPES.NONE
    },
    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
    ...mapGetters({
      getModuleConfig: "dashboardModule/getConfig",
    }),
  },
  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
  },
  mounted() {
    this.fetchRedeemCodeBundle()
    this.fetchRedeemCodes()
    this.fetchLineMerchant()
    this.fetchFields()
    this.initialized = true
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query };
      this.fetchRedeemCodes();
    },
  },
  methods: {
    async fetchRedeemCodeBundle() {
      this.showLoading = true
      const response = await redeemCodeBundleApi.getRedeemCodeBundle(this.$route.params.redeemCodeBundleId)
      this.redeemCodeBundle = response.data.data
      this.showLoading = false
    },
    async fetchRedeemCodes() {
      this.showLoading = true
      let params = { ...this.query }
      const response = await redeemCodeBundleApi.getRedeemCodes(this.$route.params.redeemCodeBundleId, params)
      this.redeemCodes = response.data.data
      this.redeemCodesMeta = response.data.meta
      this.totalRows = response.data.meta.total
      this.showLoading = false
    },
    async fetchLineMerchant() {
      const { data } = await merchantApi.list({
        type: "LINE",
      });
      this.lineMerchant = data.data[0];
    },
    async fetchFields() {
      let normalizeProfileFields = await this.normalizeProfileFields();
      // 找到插入的位置
      const insertIndex = this.baseFields.findIndex(
        (field) => field.key === "branch.name"
      );

      // 創建新的欄位陣列，插入 normalizeProfileFields
      this.fields = [
        ...this.baseFields.slice(0, insertIndex), // 從開頭到插入點之前的欄位
        ...normalizeProfileFields,          // 插入 normalizeProfileFields
        ...this.baseFields.slice(insertIndex),   // 插入點之後的欄位
      ];

    },
    async normalizeProfileFields() {
      const customerList = this.getModuleConfig("customer", "reward.customer_list");

      const profileMappings = customerList?.[0]?.member_fields ?? [];

      if (profileMappings) {
        return profileMappings.map((mapping) => {
          if (mapping.matched_key.startsWith("extra.")) {
            const key = mapping.matched_key.split("extra.")[1];
            return {
              key: `profiles.${key}`,
              label: mapping.title,
            };
          } else {
            return {
              key: mapping.matched_key,
              label: mapping.title,
            };
          }
        });
      } else {
        return [];
      }
    },
    showBatchAssignModal() {
      this.batchAssignStep = 1;
      this.$bvModal.show('modal-batch-assign')
    },
    showAssignModal(redeemCode) {
      this.selectedRedeemCode = redeemCode
      this.$refs.staffAddCustomer.showModal()
    },
    showUploadModal() {
      this.imoprtRedeemCodeStep = 1;
      this.$bvModal.show('modal-upload')
    },
    showCreateModal() {
      this.redeemCodeInput = ''
      this.$bvModal.show('modal-create')
    },
    toggleSelectAll(value) {
      this.selectedRedeemCodes = value ? this.redeemCodes.map(redeemCode => redeemCode.id) : []
    },
    toggleSelect() {
      this.isSelectAll = this.selectedRedeemCodes.length === this.redeemCodes.length
    },
    openDeleteMode() {
      this.isDeleteMode = true
      this.fields.unshift({ key: "checkbox" })
    },
    closeDeleteMode() {
      this.isDeleteMode = false
      this.fields.shift()
      this.selectedRedeemCodes = []
      this.isSelectAll = false
    },
    async deleteRedeemCodes() {
      await this.$swal
        .fire({
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              您將刪除 ${this.selectedRedeemCodes.length} 筆序號，且無法復原。
            </div>`,
          type: "warning",
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            let response = await redeemCodeBundleApi.deleteRedeemCodes(this.$route.params.redeemCodeBundleId, {
              redeem_code_ids: this.selectedRedeemCodes
            })

            if (response.status === 200) {
              this.$swal.fire({ title: "成功刪除", type: "success" })
              this.closeDeleteMode()
              this.fetchRedeemCodes()
            } else {
              if (response.data.message) {
                this.$swal.fire({
                  title: '錯誤',
                  type: "error",
                  text: response.data.message,
                })
              }
            }
          }
        })
    },
    async preProcessBatchAssign() {
      this.showLoadingUpload = true;
      let formData = new FormData();
      formData.append("file", this.batchAssignFile);

      try {
        const { data } = await redeemCodeBundleApi.preProcessBatchAssign(this.$route.params.redeemCodeBundleId, formData)
        this.batchAssignAnalysis = data
        this.batchAssignStep = 2;
      } catch (e) {
        this.importFailHandler(e)
      } finally {
        this.showLoadingUpload = false;
      }
    },
    goBackBatchAssign() {
      this.batchAssignFile = null
      this.batchAssignStep = 1
    },
    async batchAssign() {
      this.showLoadingUpload = true;
      let formData = new FormData();
      formData.append("file", this.batchAssignFile);

      try {
        await redeemCodeBundleApi.batchAssign(this.$route.params.redeemCodeBundleId, formData);
        this.$swal.fire({ title: "上傳成功", type: "success" });

        this.fetchRedeemCodes()
      } catch (e) {
        this.importFailHandler(e)
      } finally {
        this.showLoadingUpload = false;
        this.$bvModal.hide("modal-batch-assign");
      }
    },
    async preProcessImoprtRedeemCode() {
      this.showLoadingUpload = true;
      let formData = new FormData();
      formData.append("file", this.file);

      try {
        const { data } = await redeemCodeBundleApi.preProcessImoprtRedeemCode(this.$route.params.redeemCodeBundleId, formData)
        this.imoprtRedeemCodeAnalysis = data
        this.imoprtRedeemCodeStep = 2;
      } catch (e) {
        this.importFailHandler(e)
      } finally {
        this.showLoadingUpload = false;
      }
    },
    importFailHandler(e) {
      console.error(e);
      if (e.response.status === 422 && e.response.data.message[0]) {
        this.$swal.fire({
          title: "檔案內容有誤",
          type: "error",
          text: e.response.data.message[0] ?? null,
        });
      } else {
        this.$swal.fire({
          title: "上傳失敗",
          type: "error",
        });
      }
    },
    async imoprtRedeemCode() {
      this.showLoadingUpload = true;
      let formData = new FormData();
      formData.append("file", this.file);

      try {
        await redeemCodeBundleApi.imoprtRedeemCode(this.$route.params.redeemCodeBundleId, formData);
        this.$swal.fire({ title: "上傳成功", type: "success" });

        this.fetchRedeemCodes()
      } catch (e) {
        this.importFailHandler(e)
      } finally {
        this.showLoadingUpload = false;
        this.$bvModal.hide("modal-upload");
      }
    },
    goBackImport() {
      this.file = null
      this.imoprtRedeemCodeStep = 1
    },
    async saveRedeemCode() {
      this.showLoadingUpload = true;

      try {
        await redeemCodeBundleApi.saveRedeemCode(this.$route.params.redeemCodeBundleId, {
          code: this.redeemCodeInput
        });
        this.$swal.fire({ title: "上傳成功", type: "success" });
        this.fetchRedeemCodes()
      } catch (e) {
        console.error({
          'res': e.response,
          'data': e.data
        });
        if (e.response.status === 422 && e.response.data.message.code[0]) {
          this.$swal.fire({
            title: "內容有誤",
            type: "error",
            text: e.response.data.message.code[0] ?? null,
          });
        } else {
          this.$swal.fire({ title: "新增失敗", type: "error" });
        }
      } finally {
        this.showLoadingUpload = false;
        this.$bvModal.hide("modal-create");
      }
    },
    async assignRedeemCode(customer) {
      try {
        await redeemCodeBundleApi.assignRedeemCode(
          this.$route.params.redeemCodeBundleId,
          this.selectedRedeemCode?.id,
          customer.id,
        );
        this.$swal.fire({ title: "指派成功", type: "success" });

        this.fetchRedeemCodes()
      } catch (e) {
        console.error(e);
        this.$swal.fire({
          title: "指派失敗",
          type: "error",
        });
      }
    },
    // 發送推播
    async pushRedeemCode(redeemCode) {
      try {
        let result = await this.$swal.fire({
          title: "提醒",
          text: "請確認是否重新推播此優惠卷訊息給用戶",
          type: "warning",
          showCancelButton: true,
          reverseButtons: false,
          confirmButtonText: "確認",
          cancelButtonText: "返回",
        })

        if (!result.value) {
          return;
        }

        await redeemCodeBundleApi.pushRedeemCode(
          this.$route.params.redeemCodeBundleId,
          redeemCode.id
        );

        this.$swal('推播成功', '已成功推送此優惠券訊息', 'success');
      } catch (error) {
        console.error(error);
        let message = error.response?.data?.message ?? '';
        this.$swal('發送推播失敗', message, 'error');
      }
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    handleSort(ctx) {
      this.query.sort_by = ctx.sortBy;
      this.query.sort_desc = ctx.sortDesc ? "desc" : "asc";
      this.handlePageReset();
    },
    async handleExport() {
      let params = {
        ...this.query,
        redeem_code_bundle_id: this.$route.params.redeemCodeBundleId,
      };
      try {
        this.showLoading = true;
        await redeemCodeApi.exportRedeemCodes(params).then(() => {
          this.$swal({
            title: '成功',
            text: "可至“我的主控台”查看紀錄及下載檔案",
            showCancelButton: true,
            cancelButtonText: '確認',
            type: 'success',
            confirmButtonText: '前往查看',
          }).then((result)=>{
            if (result.value) {
              this.$router.push({
                name: "MyConsoleDownloadFileList",
                params: { org_code: this.$route.params.org_code },
              });
            }
          });
        });
      } catch (e) {
        console.log(e)
      }
      this.showLoading = false
    },
    hiddenString(value, length) {
      value = value || ''
      const splittedValue = [...value]
      if (splittedValue.length <= length) {
        return value
      }
      return splittedValue.slice(0, length).join('') + '...'
    },
  },
};
</script>
